@import variables

#privacy
    .textContainer
        max-width: 490px
    .privacy
        width: 400px
        margin: 0 auto
        @media (min-width: $layout-breakpoint-small)
            position: absolute
            left: 600px
            width: auto
            top: 50%
            transform: translateY(-50%)
        @media (min-width: $layout-breakpoint-2xl)
            left: unset
            right: 200px
            height: 800px