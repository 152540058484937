@import variables

header
    background-color: $header-bg
    nav
        padding:30px 0
        .showMenuNav
            display: block
            position: absolute
            width: 100%
            height: 100vh
            top: 0
            left: 0
            background: black
            z-index: 10
            display: flex
            flex-direction: column
            justify-content: space-evenly
            align-items: center
        .hideMenuNav
            display: none