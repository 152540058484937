/* BREAKPOINTS */
$layout-breakpoint-small: 640px
$layout-breakpoint-medium: 768px
$layout-breakpoint-large: 1024px
$layout-breakpoint-xl: 1280px
$layout-breakpoint-2xl: 1536px
    
    
/* COLOR PALETTE */

$primary-color: #0D2FAB
$secondary-color: #3874ff
$text-primary: rgba(255,255,255,1)
$header-bg: rgba(0,0,0,0.7)
$a-cta-fill: rgba(56,116,255,.15)
$bg-color: #0a0a0a
$color-footer-title: $secondary-color
$color-link-from: $text-primary
$color-link-to: $secondary-color


/* MISC */
$padding: 20px