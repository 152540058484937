@import variables

#timelock
    background-image: url(../../public/images/bg-timelock.jpg)
    .textContainer
        max-width: 490px
    .timelock
        @media (min-width: $layout-breakpoint-small)
            position: absolute
            right: 750px
            height: 400px
            top: 50%
            transform: translateY(-50%)
        @media (min-width: $layout-breakpoint-2xl)
            right: unset
            left:0
            height:600px