@import variables

/* Fundamentals */
*
    font-family: "Inter", Arial, sans-serif
h1,h2,h3,h4,h5,h6,strong
    letter-spacing:-0.02em
h1,h2,h3,h4,h5,h6
    font-weight: 700

a
    color: $color-link-from
    transition: 0.2s color
    &:hover
        color: $color-link-to

header,
section,
footer
    display: flex
    justify-content: center
    color: $text-primary
    .container
        width: calc(100% - calc($padding *2))
        margin: 0 auto
        @media (min-width: $layout-breakpoint-medium)
            width: calc(100% - calc($padding *8))
        @media (min-width: $layout-breakpoint-large)
            width: calc(100% - calc($padding *16))

section
    h1
        font-size: 28px

a.cta
    font-size: 16px
    padding: 15px 20px
    background-color: $a-cta-fill
    font-weight: 700
    border: 1px $primary-color solid
    border-radius: 5px
    transition: 0.2s background-color
    &:hover
        background-color: $primary-color
    @media (min-width: $layout-breakpoint-small)
        font-size: 18px
        padding: 20px 30px


.desktop
    @media (max-width: $layout-breakpoint-small)
        display: none
.mobile
    @media (min-width: $layout-breakpoint-small)
        display: none